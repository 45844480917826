export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Karşılama',
    route: 'Welcome',
    icon: 'CoffeeIcon',
    resource: 'APP_CMS',
    action: 'read',
  },
  {
    title: 'Giriş Bağlantıları',
    route: 'Buttons',
    icon: 'GridIcon',
    resource: 'APP_CMS',
    action: 'read',
  },
  {
    title: 'Etkinlikler',
    route: 'Events',
    icon: 'GiftIcon',
    resource: 'APP_CMS',
    action: 'read',
  },
  {
    title: 'Bağlantılar',
    route: 'Links',
    icon: 'LinkIcon',
    resource: 'APP_CMS',
    action: 'read',
  },
  {
    title: 'Reklam Banner',
    route: 'Banners',
    icon: 'TrelloIcon',
    resource: 'APP_CMS',
    action: 'read',
  },
  {
    title: 'Sosyal Medya',
    route: 'Socialmedia',
    icon: 'MessageCircleIcon',
    resource: 'APP_CMS',
    action: 'read',
  },
  {
    title: 'Kullanıcılar',
    route: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'ADMIN_USERS',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'UserTypes',
        resource: 'ADMIN_USER_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ayarlar',
    route: null,
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Genel Yapılandırma',
        route: 'Configs',
        resource: 'ADMIN_CONFIG',
        action: 'read',
      },
      {
        title: 'SEO Yapılandırma',
        route: 'Components',
        resource: 'ADMIN_CONFIG',
        action: 'read',
      },
      {
        title: 'SMTP',
        route: 'Smtp',
        resource: 'ADMIN_SMTP',
        action: 'read',
      },
    ],
  },
]
